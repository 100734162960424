// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  mode: 'Development',

  apiUrl : 'https://miragecityeasyinapi.mnc-control.com/',//'https://miragecityeasyinapi.mnc-control.com/',


  firebaseConfig : {
    apiKey: "AIzaSyCnoZIrlnXHd0ktkypzGs0ZoWfubrAj93E",
  authDomain: "miragecitycompound.firebaseapp.com",
  projectId: "miragecitycompound",
  storageBucket: "miragecitycompound.appspot.com",
  messagingSenderId: "894627007925",
  appId: "1:894627007925:web:8e37202ad6a6f4ee5a1dfc",
  measurementId: "G-051LVLYTNK"
  },
  whitelistedDomains: ['miragecityeasyinapi.mnc-control.com'],
  blacklistedRoutes: ['miragecityeasyinapi.mnc-control.com/ClientAuthentication']

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in
 * mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
